.chat-wrap {
  padding-bottom: 52px;
  &.chat-wrap-opened {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.chat {
  max-height: 600px;
  overflow: scroll;
}

.chat-vote:hover {
  cursor: pointer;
  background-color: #aaaaaa;
}

.chat-input {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.chat-messages {
  min-height: 300px;
  padding-top: 10px;
  .tag.is-danger {
    height: 24px;
    &:hover {
      background-color: $danger-dark !important;
    }
  }
  .tag.is-warning {
    height: 24px;
    &:hover {
      background-color: $warning-dark !important;
    }
  }
}

.mess {
  border-radius: 4px;
  padding: 3px 0;
  position: relative;
  .message-line {
    font-size: 0.9rem;
    line-height: 1.3;
    padding: 0 5px;
    background-color: transparent;
  }
  &:hover {
    background-color: #e0e0e0;
    .message-options {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 26px;
    }
  }
  .message-options {
    cursor: pointer;
    display: none;
  }
}

.message-name {
  font-weight: 800;
}

.poll-box {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 60px;
  transform: translateY(-100%);
  transition: all .2s ease-in;
  overflow-y: scroll;
  opacity: 0;
  &.opened {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 60px;
    transform: translateY(0);
    transition: all .2s ease-out;
    opacity: 1;
  }
}
