@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

@import "./styles/Bulma.scss";

@import "./pages/Users/Users.scss";

@import "./components/Chat/Chat.scss";
@import "./components/MoneyRain/MoneyRain.scss";

hr {
  margin: 0.3rem 0;
}

@media screen and (max-width: 1024px) {
  .navbar-menu {
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .hide-on-desktop {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .p-0-mobile {
    padding: 0;
  }
}

.main .notification:not(.is-success) {
  border: 1px solid #d6d6d6;
}

.main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #f0a309;
    font-family: "Luckiest Guy", cursive;
    letter-spacing: 1.5px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #654504;
    font-weight: initial;
  }
}

.dark {
  color: #f2f2f2;

  hr {
    background-color: #1f1f1f;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #f0a309;
  }

  strong,
  label {
    color: inherit;
  }

  &.main {
    background: #232323;
  }

  input,
  select,
  table,
  .box,
  table tr th {
    background-color: #232323;
    color: #f2f2f2;
  }

  input {
    border: 1px solid #494646;
    &::placeholder {
      color: #494646;
    }
  }

  .notification:not(.is-success):not(.is-warning),
  .box {
    background-color: #1f1f1f;
    border: 1px solid #494646;
  }

  .notification.is-warning.is-light, .notification.is-warning {
    background-color: #fffaeb47;
    color: #f1f1f1;
  }

  .design-stuff {
    &:before {
      background: url(./img/section_before_dark.svg) no-repeat bottom center;
    }

    &:after {
      background: url(./img/section_before_dark.svg) no-repeat bottom center;
    }
  }

  .mess:hover {
    background-color: #2c2c2c;
  }

  .message-name {
    filter: brightness(8);
  }
}

#root {
  min-height: 100vh;
  height: 100%;
  > div.main {
    min-height: calc(100vh + 150px);
    position: relative;
    padding-bottom: 150px;
  }
}

.navbar-bg {
  position: sticky;
  top: 0;
}

.avatar {
  padding: 0.25rem 0.75rem;
}

.avatar-list {
  max-height: 110px;
  overflow-y: scroll;
  .avatar-item {
    display: inline;
    float: left;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.hero-bg {
  background: rgba(55, 67, 208, 1);
  background: radial-gradient(
    circle,
    rgba(55, 67, 208, 1) 10%,
    rgba(30, 9, 89, 1) 100%
  );
  min-height: calc(100vh - 56px);
  user-select: none;
  height: 100%;
  .hero-body {
    padding-bottom: 250px;
    height: calc(100vh + 120px);
    text-shadow: 0.15vw 1px 0px #232323;
  }
  * {
    z-index: 1;
  }
}

.hero-text {
  font-family: "Luckiest Guy", cursive;
  font-size: 9vw;
  color: #f0a309;
  text-shadow: 0.1vw 1px 0px #232323;
  // cursor: pointer;
  &-amount {
    line-height: 14vw;
    font-size: 14vw;
    height: 10vw;
  }
}

.howitworks {
  img {
    height: 200px;
    width: 200px;
  }
}

.design-stuff {
  &:before {
    background: url(./img/section_before.svg) no-repeat bottom center;
    filter: drop-shadow(99px 0 0 #f0a309);
    background-size: 100%;
    position: absolute;
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    top: calc(-100% + 1px);
    left: 0;
    z-index: 2;
  }
  &:after {
    background: url(./img/section_before.svg) no-repeat bottom center;
    filter: drop-shadow(99px -2px 0 #f0a309);
    background-size: 100%;
    position: absolute;
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    bottom: -100%;
    left: 0;
    z-index: 2;
    transform: rotate(180deg);
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.position-relative {
  position: relative;
}

.live-tag {
  animation: pulse-animation 3s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(241, 70, 104, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(241, 70, 104, 0);
  }
}

.player-wrapper {
  width: 100%;
  > div {
    aspect-ratio: 16 / 9;
  }
}

.tags {
  flex-wrap: nowrap;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 150px;
}

.example-table {
  min-width: 1024px;
  tbody div p.text {
    display: inline-block;
    margin-bottom: 0;
  }
}

.poll-show-wrapper {
  background: whitesmoke;
}

.profile img {
  height: initial;
  max-width: initial;
}