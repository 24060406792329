// Update Bulma's global variables
$primary: #f0a309;
$green: #0e8b3e;


$size-1: 10rem;
$size-2: 7rem;

$tablet: 1024px;

// Update some of Bulma's component variables
$body-background-color: #fff;
// $body-background-color: #fafafa;
$control-border-width: 1px;
$border: #f0a309;
$input-border-color: #d3d3d3;
$input-focus-border-color: #d3d3d3;
$input-shadow: none;

$navbar-item-color: #fafafa;
$navbar-item-hover-color: #fafafa;
$navbar-item-hover-background-color: #2e2e2e;
$navbar-dropdown-background-color: red;
$navbar-background-color: #1f1f1f;

@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";