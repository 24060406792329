.users-page {
    .users-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      // grid-template-rows: 350px;
      grid-gap: 10px;
      li {
        border: 1px solid #d6d6d6;
        padding: 10px;
        border-radius: 4px;
        &.users-mod {
          border-color: rgb(8, 219, 96);
          // background-color: rgba(0, 255, 149, 0.05);
        }
        &.users-admin {
          border-color: rgb(255, 188, 17);
          // background-color: rgba(218, 165, 32, 0.05);
        }
        &.users-banned {
          border-color: red;
          // background-color: rgba(255, 0, 0, 0.05);
        }
      }
      &-header {
        display: flex;
        align-items: center;
      }
      p {
        line-height: 1.1;
      }
    }
  }